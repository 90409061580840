// Modules opbouwen via volgende structuur
// Meer info: https://learn.jquery.com/code-organization/concepts/
var blockSlider = $( '.js-block-slider' );

(function($) {

    $('.slider').slick({
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'linear',
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
    });

    $('.article-slider').slick({
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
    });

    blockSlider.slick( {
      arrows: true,
      nextArrow: '<div class="arrow arrow-right js-right-arrow"><i class="fa fa-angle-right"></div>',
      prevArrow: '<div class="arrow arrow-left js-left-arrow"><i class="fa fa-angle-left"></div>',
      appendArrows: $( '.js-block-slider' ),
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [ {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    } );

})(jQuery);
