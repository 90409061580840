(function() {
    var GoogleMapsLoader = require('google-maps');
    GoogleMapsLoader.KEY = "AIzaSyAmlHrSH298hvlejGpdWjTZ04--JOHXqo8";

    GoogleMapsLoader.load(function(google) {

        var map;

        function initialize() {
            var glat = 51.209400;
            glong = 3.230038;

            var mapOptions = {
                zoom: 19,
                scrollwheel: false,
                center: new google.maps.LatLng(glat, glong),
                styles: [{
                        "featureType": "administrative.land_parcel",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "poi.business",
                        "elementType": "labels.text.fill",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels",
                        "stylers": [{
                            "visibility": "off"
                        }]
                    }
                ]
            };
            map = new google.maps.Map(document.getElementById('maps'), mapOptions);

            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(glat, glong),
                map: map,
                icon: '/bundles/sitefrontend/img/marker.png'
            });

        }
        google.maps.event.addDomListener(window, 'load', initialize);
    });
})();
